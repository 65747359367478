<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.w {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 30px;
  min-height: 704px;

  border-radius: 10px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
}
.m_50 {
  height: 50px;
}
.title {
  padding-left: 27px;
  height: 98px;
  line-height: 98px;
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  border-bottom: 2px solid #f2f2f2;
}
.order {
  padding-left: 27px;
}
.num {
  height: 66px;
  line-height: 66px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  background-color: #fff;
}
.list {
  margin-right: 27px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
  .item {
    padding: 27px;
    background-color: #f2f2f2;
    justify-content: space-between;
    .item_left {
      margin-top: 27px;
      &:first-child {
        margin-top: 0;
      }
      .img {
        width: 200px;
        height: 121px;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 20px;
      }
      .name {
        line-height: 50px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .item-right {
      height: 121px;
      line-height: 121px;
      margin-right: 100px;
      font-size: 18px;
      font-weight: 500;
      color: #f13232;
    }
  }
}
.pay {
  margin: 0 27px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  .way {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    height: 66px;
    line-height: 66px;
  }
  .pay_img {
    cursor: pointer;
    width: 166px;
    height: 68px;
    background-color: antiquewhite;
  }
}
.bottom {
  padding-top: 25px;
  margin: 0 27px;
  font-weight: 500;
  color: #333333;
  .money_box {
    justify-content: flex-end;
    .money {
      margin-bottom: 10px;
      width: 135px;
      text-align: right;
      font-size: 18px;
      font-weight: 500;
      color: #f13232;
    }
  }
  .btns {
    padding-bottom: 60px;
    margin-top: 15px;
    justify-content: flex-end;
    .btn {
      cursor: pointer;
      text-align: center;
      margin-left: 15px;
      font-size: 16px;
      font-weight: 500;
      color: #999999;
      width: 130px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #cccccc;
      border-radius: 20px;
      box-sizing: border-box;
      color: #fff;
      border: 0;
      background: #f13232;
    }
  }
}
</style>
<template>
  <div>
    <div class="w" v-if="flag">
      <div class="title">创建订单</div>
      <div class="order">
        <div class="num">商品信息</div>
        <div class="list">
          <div class="item flex" v-for="(item,index) in orderData.order.culum " :key="index">
            <div class="list_img">
              <div class="flex item_left">
                <div class="img" v-if="type!=3">
                  <img :src="item.alias_litpic" />
                </div>
                <div class="name">{{item.alias_name}}</div>
              </div>
            </div>
            <div class="item-right">¥ {{item.alias_price}}</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="money_box flex1">
          商品总金额：
          <div class="money">¥ {{orderData.order.total}}</div>
        </div>
        <div class="money_box flex1">
          优惠：
          <div class="money">-¥ {{ orderData.order.discount }}</div>
        </div>
        <div class="money_box flex1">
          应付：
          <div class="money">¥ {{orderData.order.profit}}</div>
        </div>
        <div class="btns flex">
          <div class="btn" @click="submit()">提交订单</div>
        </div>
      </div>
    </div>

    <div class="m_50"></div>
  </div>
</template>
<script>
import { createOrder, insertOrder } from "../../assets/js/order";
export default {
  data() {
    return {
      imgUrl: "http://v2.hnzye.cn",
      type: 1,
      orderData: "",
      flag: false,
      siteId: window.localStorage.getItem("siteId"),
      user_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.getCreat();
  },
  mounted() {},
  methods: {
    getCreat() {
      let culum_id = this.$route.query.culum_id;
      createOrder({
        type: this.type,
        siteId: this.siteId,
        culum_id,
      }).then((res) => {
        // console.log(res);
        this.flag = true;
        this.orderData = res.data;
      });
    },
    submit() {
      insertOrder({
        type: this.type,
        siteId: this.siteId,
        culum_id: this.$route.query.culum_id,
        user_id: this.user_id,
      }).then((res) => {
        this.$router.push({
          path: "/payment",
          query: { order_id: res.data.order_id },
        });
      });
    },
  },
};
</script>